import Vue from 'vue';
import Router from 'vue-router';
import constants from './constant';

Vue.use(Router);

let routes = [
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        name: '404',
        component: () => import (/* webpackChunkName: "error-pages" */ './views/404.vue')
    },
    {
        path: '/auth/sso/error',
        name: 'ssoError',
        component: () => import(/* webpackChunkName: "error-pages" */ './views/Auth/SSO/ErrorHandler.vue')
    },
    {
        path: '/',
        name: 'login',
        component: () => import (/* webpackChunkName: "auth-pages" */ './views/Auth/Login.vue')
    },
    {
        path: '/auth/sso',
        name: 'sso',
        component: () => import (/* webpackChunkName: "auth-pages" */ './views/Auth/SingleSignOn.vue')
    },
    {
        path: '/auth/slo',
        name: 'slo',
        component: () => import (/* webpackChunkName: "auth-pages" */ './views/Auth/SingleLogout.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            requiresAuth: true,
        },
        component: () => import (/* webpackChunkName: "home-pages" */ './views/Home.vue')
    },
    {
        path: '/setting',
        name: 'setting',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "my-account-pages" */ './views/Setting.vue')
    },
    {
        path: '/volunteering-timesheet/:applicationId?',
        name: 'Volunteering timesheet ',
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.SETTING_VOLUNTEERING
            ]
        },
        component: () => import (/* webpackChunkName: "volunteering-pages" */ './views/VolunteeringTimesheet.vue')
    },
    {
        path: '/volunteering-history',
        name: 'Volunteering history ',
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.SETTING_VOLUNTEERING
            ]
        },
        component: () => import (/* webpackChunkName: "volunteering-pages" */ './views/VolunteeringHistory.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "volunteering-pages" */ './views/Dashboard.vue')
    },
    {
        path: '/donation-dashboard',
        name: 'donationDashboard',
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.DONATION_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "donation-pages" */ './views/DonationDashboard.vue')
    },
    {
        path: '/news',
        name: 'News',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.NEWS_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "news-pages" */ './views/News.vue')
    },
    {
        path: '/stories',
        name: 'Stories',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/Stories.vue')
    },
    {
        path: '/stories/:missionId',
        name: 'MissionStories',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/Stories.vue')
    },
    {
        path: '/news-detail/:newsId',
        name: 'NewsDetail',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.NEWS_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "news-pages" */ './views/NewsDetail.vue')
    },
    {
        path: '/story-detail/:storyId',
        name: 'StoryDetail',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/StoryDetail.vue')
    },
    {
        path: '/story-preview/:storyId',
        name: 'StoryPreview',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.STORIES_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/StoryDetail.vue')
    },
    {
        path: '/share-story/:mission?',
        name: 'ShareStory',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.STORIES_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/ShareStory.vue')
    },
    {
        path: '/edit-story/:storyId',
        name: 'EditStory',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.STORIES_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/ShareStory.vue')
    },
    {
        path: '/messages',
        name: 'DashboardMessage',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.MESSAGE
            ]
        },
        component: () => import (/* webpackChunkName: "other-pages" */ './views/DashboardMessage.vue')
    },
    {
        path: '/comment-history',
        name: 'DashboardComments',
        meta: {
            requiresAuth: true,
        },
        component: () => import (/* webpackChunkName: "other-pages" */ './views/DashboardComments.vue')
    },
    {
        path: '/my-stories',
        name: 'DashboardStories',
        meta: {
            requiresAuth: true,
            requiredSettings: [
              constants.STORIES_ENABLED
            ]
        },
        component: () => import (/* webpackChunkName: "stories-pages" */ './views/DashboardStories.vue')
    },
    {
        path: '/home/:searchParamsType/:searchParams',
        name: 'exploreMission',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "home-pages" */ './views/Home.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'resetPassword',
        component: () => import (/* webpackChunkName: "auth-pages" */ './views/Auth/ResetPassword.vue')
    },
    {
        path: '/my-account',
        name: 'myAccount',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "my-account-pages" */ './views/MyAccount.vue')
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: () => import (/* webpackChunkName: "auth-pages" */ './views/Auth/ForgotPassword.vue')
    },
    {
        path: '/payment',
        name: 'donationAccountPaymentMethod',
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.DONATION
            ]
        },
        component: () => import(/* webpackChunkName: "my-account-pages" */ './views/DonationAccountPaymentMethod.vue'),
    },
    {
        path: '/recurring-payment',
        name: 'donationAccountRecurringPayment',
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.DONATION
            ]
        },
        component: () => import(/* webpackChunkName: "my-account-pages" */ './views/DonationAccountRecurringPayment.vue'),
    },
    {
        path: '/teams/:teamId/manage-members',
        name: 'Members',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/Members.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/teams',
        name: 'teamPage',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams'),
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.TEAM_VOLUNTEERING
            ]
        }
    },
    {
        path: '/teams/:teamId/update',
        name: 'UpdateTeam',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/TeamManage'),
        props:true,
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.TEAM_VOLUNTEERING
            ]
        }
    },
    {
        path: '/teams/:teamId/members',
        name: 'Members',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/TeamMembers.vue'),
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.TEAM_VOLUNTEERING
            ]
        }
    },
    {
        path: '/teams/:teamId/reports',
        name: 'AttendanceReport',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/AttendanceReport.vue'),
        meta: {
            requiresAuth: true,
            requiredTeamAccessRole: [
                constants.TEAM_ACCESS_ROLE.ADMIN
            ]
        }
    },
    {
        path: '/teams/create',
        name: 'CreateTeam',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/TeamManage'),
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.TEAM_VOLUNTEERING
            ]
        }
    },
    {
        path: '/teams/:teamId',
        name: 'TeamDetails',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/TeamDetails'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/teams/:teamId/activity',
        name: 'Activity',
        component: () => import(/* webpackChunkName: "teams-pages" */ './views/Teams/Activity'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile/:userID',
        name: 'profilePage',
        component: () => import(/* webpackChunkName: "other-pages" */ './views/UserProfile'),
        meta: {
            requiresAuth: true,
            requiredSettings: [
                constants.TEAM_VOLUNTEERING
            ]
        }
    },
    {
        path: '/:slug',
        name: 'cms',
        component: () => import (/* webpackChunkName: "other-pages" */ './views/Cms.vue')
    },
    {
        path: '/home/:searchParamsType',
        name: 'exploreMissions',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "home-pages" */ './views/Home.vue')
    },
    {
        path: '/mission-detail/:misisonId',
        name: 'missionDetail',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "volunteering-pages" */ './views/MissionDetail.vue')
    },
    {
        path: '/donation-mission-detail/:misisonId',
        name: 'donationMissionDetail',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "donation-pages" */ './views/DonationMissionDetail.vue')
    },
    {
        path: '/policy/:policyPage',
        name: 'policy',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "other-pages" */ './views/Policy.vue')
    },
    {
        path: '/prescreening-step/:missionId/:teams?',
        name: 'prescreening',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "volunteering-pages" */ './views/PreScreening/index.vue')
    },
    {
        path: '/prescreening/:missionId',
        name: 'prescreening',
        meta: {
            requiresAuth: true
        },
        component: () => import (/* webpackChunkName: "volunteering-pages" */ './views/prescreening.vue')
    },
    {
        path: '/mission/:missionId/donate',
        component: () => import (/* webpackChunkName: "donation-pages" */ '@views/Donation'),
        meta: {
            requiresAuth: true,
            checkDonationEligibility: true
        }
    }
];
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
